import React from "react";
import { graphql } from "gatsby";
import { Button, Container } from "react-bootstrap";
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import HomePage from "../templates/HomePage";
import SimplePage from "../templates/SimplePage";
import Ideas from "../templates/Ideas";
import PostPage from "../templates/PostPage";
import About from "../templates/About";
import Collection from "../templates/Collection";
import ServicePage from "../templates/ServicePage";
import ProjectPage from "../templates/ProjectPage";
import ProductPage from "../templates/ProductPage";
import NascoJobs from "../templates/NascoJobs";
import repositoryConfigs from "../../config/prismic-previews-provider-config";
import { constructActiveDoc } from "../utilities/helpers";

const NotFoundPage = ({ data }) => {
  if (!data) return null;

  const {
    fof_title: title,
    fof_link: link,
    fof_link_label: linkLabel,
  } = data.settings.data;

  return (
    <Layout
      settings={data.settings}
      activeDocMeta={constructActiveDoc(data.settings)}
    >
      <Container className="py-6 text-center">
        <h1 className="py-5">{title}</h1>
        <Button as={Anchor} href={link.url} className="by-5">
          {linkLabel}
        </Button>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query notFoundQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
  }
`;

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    ...repositoryConfigs[0],
    componentResolver: componentResolverFromMap({
      homepage: HomePage,
      simple_page: SimplePage,
      collection: Collection,
      product: ProductPage,
      project: ProjectPage,
      service: ServicePage,
      post: PostPage,
      nasco_jobs: NascoJobs,
      ideas: Ideas,
      about: About,
    }),
  },
]);
