import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Container } from "react-bootstrap";
import { SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import components from "../components/slices/Index";
import { constructActiveDoc } from "../utilities";

const ServicePage = ({ data }) => {
  if (!data) return null;
  const { prismicSettings: settings, service } = data;

  return (
    <>
      <Seo page={service} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(service)}>
        <Container className="my-5">
          <h1>{service.data.title}</h1>
          <SliceZone slices={service.data.body} components={components} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query serviceQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    service: prismicService(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      uid
      tags
      data {
        title
        meta_title
        meta_description
        meta_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        body {
          ... on PrismicServiceDataBodyRichText {
            id
            slice_type
            slice_label
            primary {
              is_red_important
              custom_button_label
              custom_button_link {
                url
                uid
                type
                id
                target
              }
              text_description {
                richText
              }
            }
          }
          ... on PrismicServiceDataBodySingleImage {
            id
            slice_type
            slice_label
            primary {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicServiceDataBodyIconTextCenter {
            id
            slice_type
            slice_label
            primary {
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              centered_rich_text {
                richText
              }
            }
          }
          ... on PrismicServiceDataBodyGallery {
            id
            slice_type
            slice_label
            primary {
              gallery_title
              button_label
            }
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicServiceDataBodySingleVideo {
            slice_type
            slice_label
            id
            primary {
              video {
                url
              }
            }
          }
          ... on PrismicServiceDataBodySingleTitle {
            id
            slice_type
            primary {
              title1 {
                richText
              }
            }
            slice_label
          }
          ... on PrismicServiceDataBodyTabs {
            id
            slice_type
            slice_label
            items {
              tab_title
              tab_color
              tab_content {
                richText
              }
            }
          }
          ... on PrismicServiceDataBodyEmbedVideo {
            id
            slice_type
            slice_label
            items {
              video {
                id
                author_name
                author_url
                embed_url
                type
                title
                thumbnail_width
                thumbnail_url
                thumbnail_height
                provider_url
                prismicId
                provider_name
                html
              }
            }
          }
          ... on PrismicServiceDataBodyTitleButtons {
            id
            slice_type
            slice_label
            primary {
              banner_title
            }
            items {
              button_label
              button_link {
                url
              }
            }
          }
          ... on PrismicServiceDataBodyGallery1 {
            id
            slice_type
            slice_label
            primary {
              gallery_title
              button_label
            }
            items {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicServiceDataBodyProductShowcase {
            id
            slice_type
            slice_label
            primary {
              section_title
            }
            items {
              select_product {
                url
                uid
                type
                link_type
                lang
                id
                document {
                  ... on PrismicProduct {
                    id
                    data {
                      price
                      title
                      product_main_image {
                        main_image {
                          alt
                          gatsbyImageData(placeholder: BLURRED)
                        }
                      }
                      description {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ServicePage);
